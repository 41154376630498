import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import Tooltip from '../../general/Tooltip';
import AttachmentForm from './AttachmentForm';
import generalFailedPC from '../../fetch/generalFailedPC';
import {Button} from 'antd';
import {Attachment} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class EditAttachment extends React.PureComponent {
    static propTypes = {
        attachment: Attachment.isRequired,
        reloadAttachments: PropTypes.func.isRequired,
    };

    render() {
        const {reloadAttachments, attachment} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Úprava prílohy</Trans>}>
                    <Button icon="edit" size="small"/>
                </Tooltip>
            }
            values={{
                attachment: attachment,
                isEdit: true
            }}
            onFinishSuccessful={reloadAttachments}
            title={<Trans>Úprava prílohy</Trans>}
            Form={AttachmentForm}
            Response={null}
            Failed={generalFailedPC(t`Prílohu sa nepodarilo upraviť.`)}
        />;
    }

}

export default EditAttachment;