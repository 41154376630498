import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Trans, t} from '@lingui/macro';
import { createFetchRightsAll } from '../../backend/apiCalls';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';

/**
 * @fero
 */

class AccessLevelSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        onChangeWithData: PropTypes.func,
        focus: PropTypes.bool,
        min: PropTypes.number,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            roles: []
        }
    }

    fetchRoles = () => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        fetchHandler(
            createFetchRightsAll(),
            {},
            (result) => {
                this.setState({roles: result});
            }
        );
    };

    componentDidMount() {
        this.fetchRoles();
    }

    handleChange = (newVal) => {
        const {onChange, onChangeWithData} = this.props;
        const {roles} = this.state;
        onChange(newVal);

        if(onChangeWithData != null)
        {
            const selectedRole = newVal != null && roles != null ? roles.find(r => r.id == newVal) : null;
            onChangeWithData(selectedRole);
        }
    }

    render() {
        const { [SESSION_ATTRIBUTES.TRUE_RIGHTS]: trueRights, 
            min, onChange, onChangeWithData, ...props } = this.props;
        const {roles} = this.state;
        const rolesFiltered = (min != null ? roles.filter(r => r.id >= min) : roles);
        return <Select
            {...props}
            onChange={this.handleChange}
            isSearchable={false}
            isOptionDisabled={(op) => op.value > trueRights}
            options={rolesFiltered.map(role => {
                return {
                    label: role.name,
                    value: role.id,
                }
            })}
        />;
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.TRUE_RIGHTS])(
    withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(AccessLevelSelect)
);