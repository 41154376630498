import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {AnalysisType} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import update from 'immutability-helper';
import { Button, Icon, InputNumber } from 'antd';
import Select from '../general/Select';
import InfoAttributeGroup from '../general/InfoAttributeGroup';
import DatePicker from '../general/DatePicker';


/**
 * @dusan
 */

class AnalysisParams extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.ANALYSIS_TYPES]: PropTypes.arrayOf(AnalysisType.isRequired).isRequired,
        params: PropTypes.shape({
            type: PropTypes.string,
            key_cols: PropTypes.array,
            data_cols: PropTypes.array,
            from: PropTypes.string,
            to: PropTypes.string,
            sampling_period: PropTypes.oneOf(['D', 'W', 'M', 'Q', 'Y']),
            samples_count: PropTypes.number,
        }),
        loading: PropTypes.bool,
        onChangeParams: PropTypes.func.isRequired,
        onRefresh: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        loading: false,
        disabled: false,
    };

    getSelectedType = () => {
        const {params, [GLOBAL_DATA.ANALYSIS_TYPES]: types} = this.props;
        const type = params != null ? params.type : null;
        return types.find(t => t.name == type);
    }
    
    onTypeChange = (newType) => {
        const {onChangeParams} = this.props;
        // reset params
        onChangeParams({
            type: newType,
            key_cols: [],
            data_cols: [],
            filter: {},
            from: null,
            to: null,
            order: null,
            sampling_period: null,
            samples_count: 1
        });
    }

    onKeyColsChange = (newCols) => {
        const {params, onChangeParams} = this.props;
        const newParams = update(params, {key_cols: {$set: newCols}});
        onChangeParams(newParams);
    }

    onDataColsChange = (newCols) => {
        const {params, onChangeParams} = this.props;
        const newParams = update(params, {data_cols: {$set: newCols}});
        onChangeParams(newParams);
    }

    onFromDateChange = (fromDate) => {
        const {params, onChangeParams} = this.props;
        const newParams = update(params, {from: {$set: fromDate}});
        onChangeParams(newParams);
    }

    onToDateChange = (toDate) => {
        const {params, onChangeParams} = this.props;
        const newParams = update(params, {to: {$set: toDate}});
        onChangeParams(newParams);
    }

    onSamplingPeriodChange = (period) => {
        const {params, onChangeParams} = this.props;
        const newParams = update(params, {sampling_period: {$set: period}});
        onChangeParams(newParams);
    }

    onSamplesCountChange = (samples) => {
        const {params, onChangeParams} = this.props;
        const newParams = update(params, {samples_count: {$set: samples}});
        onChangeParams(newParams);
    }

    checkParams = () => {
        const {params} = this.props;
        if(params == null || params.type == null)
            return false;

        const type = this.getSelectedType();
        if(type == null)
            return false;

        return ((type.statefull || params.from != null) && params.data_cols != null && params.data_cols.length > 0);
    }

    resetParams = () => {
        const {onChangeParams} = this.props;
        onChangeParams({
            type: null,
            key_cols: [],
            data_cols: [],
            filter: {},
            from: null,
            to: null,
            order: null,
            sampling_period: null,
            samples_count: 1
        });
    }

    render() {
        const {params, [GLOBAL_DATA.ANALYSIS_TYPES]: types, onRefresh, loading, disabled} = this.props;
        const paramsChecked = params != null ? params : {};
        const type = this.getSelectedType();
        const typeChecked = type != null ? type : {};

        return <div className="d-flex flex-wrap p-3">
            <InfoAttributeGroup
                title={<Trans>Údaje</Trans>}
                attributes={[
                    {
                        title: <Trans>Typ analýzy:</Trans>,
                        value: <Select
                            onChange={this.onTypeChange}
                            value={paramsChecked.type}
                            options={types.map((t) => {
                                return {value: t.name, label: t.label};
                            })}
                            allowClear={true}
                            disabled={loading}
                        />,
                        isRequired: true,
                        hasError: (paramsChecked.type == null)
                    },
                    {
                        title: <Trans>Vypočítať sumáre:</Trans>,
                        value: <Select
                            value={paramsChecked.data_cols}
                            onChange={this.onDataColsChange}
                            isMulti={true}
                            options={typeChecked.data_cols != null ? 
                                typeChecked.data_cols.map((col) => {
                                    return {value: col.col, label: col.caption};
                                }) : []
                            }
                            disabled={loading || typeChecked.data_cols == null}
                            placeholder={<Trans>Vyberte...</Trans>}
                        />,
                        isRequired: true,
                        hasError: typeChecked.data_cols != null && (paramsChecked.data_cols == null || paramsChecked.data_cols.length == 0)
                    },
                    {
                        title: <Trans>Analyzovať podľa:</Trans>,
                        value: <Select
                            value={paramsChecked.key_cols}
                            onChange={this.onKeyColsChange}
                            isMulti={true}
                            options={typeChecked.key_cols != null ? 
                                typeChecked.key_cols.map((col) => {
                                    return {value: col.col, label: col.caption};
                                }) : []
                            }
                            disabled={loading || typeChecked.key_cols == null}
                        />
                    }
                ]}
            />
            <InfoAttributeGroup
                title={<Trans>Obdobie</Trans>}
                attributes={[
                    {
                        title: <Trans>Začiatok:</Trans>,
                        value: <DatePicker
                            value={paramsChecked.from}
                            onChange={this.onFromDateChange}
                            placeholder={typeChecked.statefull ? undefined : t`Zadajte`}
                            disabled={loading || paramsChecked.type == null || typeChecked.statefull}
                        />,
                        isRequired: !typeChecked.statefull,
                        hasError: (typeChecked.data_cols != null && paramsChecked.from == null && !typeChecked.statefull)
                    },
                    {
                        title: <Trans>Koniec:</Trans>,
                        value: <DatePicker
                            value={paramsChecked.to}
                            onChange={this.onToDateChange}
                            placeholder={t`Predošlý deň`}
                            disabled={loading || paramsChecked.type == null}
                        />
                    },
                    {
                        value: <Button
                            onClick={this.resetParams}
                            className="full-size-width mt-2"
                            type="danger"
                            disabled={paramsChecked.type == null}
                        >
                            <Icon type="delete" className="mr-2"/>
                            <Trans>Vyčistiť všetky parametre</Trans>
                        </Button>
                    }
                ]}
            />
            <InfoAttributeGroup
                title={<Trans>Porovanie s minulosťou</Trans>}
                attributes={[
                    {
                        title: <Trans>Perióda porovnávania:</Trans>,
                        value: <Select
                            value={paramsChecked.sampling_period}
                            onChange={this.onSamplingPeriodChange}
                            options={[
                                {value: 'Y', label: <Trans>rok</Trans>},
                                {value: 'Q', label: <Trans>kvartál</Trans>},
                                {value: 'M', label: <Trans>mesiac</Trans>},
                                {value: 'W', label: <Trans>týždeň</Trans>},
                                {value: 'D', label: <Trans>deň</Trans>}
                            ]}
                            allowClear={true}
                            disabled={loading || paramsChecked.type == null}
                        />
                    },
                    {
                        title: <Trans>Počet periód porovnania:</Trans>,
                        value: <InputNumber
                            value={paramsChecked.samples_count || 1}
                            onChange={this.onSamplesCountChange}
                            min={1}
                            step={1}
                            disabled={loading || paramsChecked.sampling_period == null}
                        />
                    },
                    {
                        value: <Button
                            type="primary"
                            onClick={onRefresh}
                            className="full-size-width"
                            disabled={disabled || !this.checkParams()}
                            loading={loading}
                        >
                            <Icon type="reload" className="mr-2"/>
                            <Trans>Generovať</Trans>
                        </Button>
                    }
                ]}
            />
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.ANALYSIS_TYPES])(AnalysisParams);