import React from 'react';
import PropTypes from 'prop-types';
import SingleFileUpload from '../../general/SingleFileUpload';
import {merge} from '../../../lib/object';
import {Button, Form, Input, Radio} from 'antd';
import {createFetchAttachmentAdd, createFetchAttachmentEdit} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout} from '../../../constants/form';
import {Attachment} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import DatePicker from '../../general/DatePicker';
import AccessLevelSelect from '../AccessLevelSelect';
import { RIGHTS } from '../../../constants/Rights';
import RightsWrapper from '../../sessionProvider/RightsWrapper';

const FormItem = Form.Item;

/**
 * @fero
 */



class AttachmentForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            isEdit: PropTypes.bool.isRequired,
            attachment: Attachment,
            quotationId: PropTypes.number,
            requestId: PropTypes.number,
            orderId: PropTypes.number,
            productId: PropTypes.number,
            userId: PropTypes.number,
            customerId: PropTypes.number,
            invoiceId: PropTypes.number,
            transferId: PropTypes.number,
            complaintId: PropTypes.number,
            eventId: PropTypes.number,
            minAccessLevel: PropTypes.string,
        })
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {isEdit, attachment = {}, quotationId, requestId, orderId, productId, userId,
            customerId, invoiceId, transferId, complaintId, eventId} = values;
        e.preventDefault();
        const availableIds = {
            id_order: orderId,
            id_product: productId,
            id_quotation: quotationId,
            id_request: requestId,
            id_user: userId,
            id_customer: customerId,
            id_invoice: invoiceId,
            id_transfer: transferId,
            id_complaint: complaintId,
            id_event: eventId,
        };
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({loading: true});
                const fetchFunction = isEdit ? createFetchAttachmentEdit() : createFetchAttachmentAdd();
                const checkedValues = isEdit ?
                    merge(values, {id: attachment.id}) :
                    merge(values, availableIds);

                fetchFunction(checkedValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {onClose, values = {}} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {attachment = {}, minAccessLevel, isEdit} = values;
        
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov</Trans>}
            >
                {getFieldDecorator('title', merge({initialValue: attachment.title}))(
                    <Input ref={node => this.first = node}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Popis</Trans>}
            >
                {getFieldDecorator('body', {
                    rules: [],
                    initialValue: attachment.body
                })(
                    <Input.TextArea autoSize={{minRows: 10, maxRows: 50}}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Súbor</Trans>}
            >
                {getFieldDecorator('file', {initialValue: attachment.file})(
                    <SingleFileUpload
                        showPreview={false}
                        isProtected={true}
                    />
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Dátum</Trans>}
                >
                    {getFieldDecorator('created_at', merge({initialValue: attachment.created_at}))(
                        <DatePicker/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Min. práva</Trans>}
                >
                    {getFieldDecorator('min_access_level', {initialValue: attachment.min_access_level ?? minAccessLevel})(
                        <AccessLevelSelect
                            min={RIGHTS.UNREGISTERED}
                        />
                    )}
                </FormItem>
            </RightsWrapper>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Vytvoriť</Trans>}
                </Button>
            </FormItem>
        </Form>;
    }

}

export default Form.create()(AttachmentForm);