import React from 'react';
import PropTypes from 'prop-types';
import EditAttachment from './EditAttachment';
import RemoveAttachment from './RemoveAttachment';
import {getDomain, addQueryParamsToUrl} from '../../../lib/url';
import {DOWNLOAD_TARGET} from '../../../constants/apiEndpoints';
import {Attachments, Attachment} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import Tooltip from '../../general/Tooltip';
import { Icon } from 'antd';
import DisplayModal from '../../general/DisplayModal';
import Date from '../../general/Date';
import AddAttachment from './AddAttachment';
import VisibilityIcon from '../../general/VisibilityIcon';
import { RIGHTS } from '../../../constants/Rights';

/**
 * @dusan
 */

class AttachmentsList extends React.PureComponent {
    static propTypes = {
        attachments: Attachments.isRequired,
        reloadAttachments: PropTypes.func.isRequired,
        allowEdit: PropTypes.bool,
        className: PropTypes.string,
    };

    render() {
        const {className, attachments, reloadAttachments, allowEdit, ...props} = this.props;
        const rows = attachments != null && Array.isArray(attachments) ? attachments : [];
        return <div className={className}>
            { rows.map((att, idx) => {
                return <AttachmentListRow
                    data={att}
                    key={idx}
                    allowEdit={allowEdit}
                    reload={reloadAttachments}
                />
            })}
            { allowEdit ? 
                <AddAttachment
                    reloadAttachments={reloadAttachments}
                    className="ml-2"
                    {...props}
                />
                : null
            }
        </div>;
        
    }
}

export default AttachmentsList;

class AttachmentListRow extends React.PureComponent {
    static propTypes = {
        data: Attachment.isRequired,
        reload: PropTypes.func,
        allowEdit: PropTypes.bool,
    };

    render() {
        const {data, reload, allowEdit} = this.props;
        const downloadUrlBase = getDomain() + DOWNLOAD_TARGET;
        const downloadUrl = addQueryParamsToUrl(downloadUrlBase, {file: data.file});
        var re = /(?:\.([^.]+))?$/;
        const ext = re.exec(data.file)[1];

        return <div className="d-flex align-items-center px-2">
            <VisibilityIcon 
                isVisible={data.min_access_level < RIGHTS.MARKETING} 
                tooltip={<Trans>neviditeľné pre zákazníka</Trans>}
            />
            { data.file != null ? 
                <Tooltip title={<Trans>Zobraziť</Trans>}>
                    <a href={downloadUrl} target="_blank">
                        <span className="attachments-table-title px-1">
                            <Icon type="file" className="attachments-table-icon pr-1"/>
                            {data.title ?? t`Súbor` + ' .' + ext }
                        </span>
                    </a>
                </Tooltip> :
                <DisplayModal
                    title={data.title}
                    openNode={<Tooltip title={<Trans>Zobraziť</Trans>}>
                        <span className="attachments-table-title px-1">
                            <Icon type="read" className="attachments-table-icon pr-1"/>
                            {data.title}
                        </span>
                    </Tooltip>}
                >
                    <pre>{data.body}</pre>
                </DisplayModal>
            }
            
            { allowEdit ? 
                <EditAttachment
                    attachment={data}
                    reloadAttachments={reload}
                />
                : null
            }
            { allowEdit ?
                <RemoveAttachment
                    attachment={data}
                    reloadAttachments={reload}
                />   
                : null
            }
        </div>;
    }
}