import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import {Button, Form} from 'antd';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import AccessLevelSelect from '../project/AccessLevelSelect';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { RIGHTS } from '../../constants/Rights';
import { ASYNC_STATE } from '../../constants/AsyncState';
const FormItem = Form.Item;

/**
 * @dusan
 */

class ChangeRightsForm extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({}).isRequired,
        [SESSION_ATTRIBUTES.TRY_RIGHTS]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedRights: null,
        };
    }

    handleSubmit = (e) => {
        const {[SESSION_ATTRIBUTES.TRY_RIGHTS]: tryRights, onSubmitFinish} = this.props;
        const {selectedRights} = this.state;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err)
            {
                tryRights(values.access_level);

                // mimic API response
                onSubmitFinish({
                    asyncState: ASYNC_STATE.SUCCEEDED,
                    result: selectedRights,
                });
            }
        });
    };

    render() {
        const {onClose, [SESSION_ATTRIBUTES.RIGHTS]: rights} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit} className="">
            <FormItem>
                {getFieldDecorator('access_level', {
                    initialValue: rights, 
                    rules: [
                        {required: true, message: 'Povinné'}
                    ],
                })(
                    <AccessLevelSelect 
                        min={RIGHTS.CUSTOMER}
                        onChangeWithData={(obj) => this.setState({selectedRights: obj})}
                    />
                )}
            </FormItem>
            <FormItem>
                <div className="d-flex flex-column">
                    <div>
                        <Button onClick={onClose}>
                            <Trans>Zrušiť</Trans>
                        </Button>
                        <Button type="primary" htmlType="submit">
                            <Trans>Zmeniť</Trans>
                        </Button>
                    </div>
                </div>
            </FormItem>
        </Form>;
    }
}

export default Form.create()(
    withSessionHOC([SESSION_ATTRIBUTES.RIGHTS, SESSION_ATTRIBUTES.TRY_RIGHTS])(ChangeRightsForm)
);