import React from 'react';
import FormModal from '../../fetch/FormModal';
import AttachmentForm from './AttachmentForm';
import generalFailedPC from '../../fetch/generalFailedPC';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class AddAttachment extends React.PureComponent {
    static propTypes = {
        reloadAttachments: PropTypes.func,
        quotationId: PropTypes.number,
        requestId: PropTypes.number,
        orderId: PropTypes.number,
        productId: PropTypes.number,
        userId: PropTypes.number,
        customerId: PropTypes.number,
        invoiceId: PropTypes.number,
        transferId: PropTypes.number,
        complaintId: PropTypes.number,
        eventId: PropTypes.number,
        minAccessLevel: PropTypes.bool,
    };

    render() {
        const {reloadAttachments, quotationId, requestId, orderId, productId, 
            customerId, invoiceId, transferId, complaintId, userId, eventId,
            minAccessLevel, className} = this.props;
        return <FormModal
            openNode={
                <Button icon="plus" size="small" className={className}>
                    <Trans>Pridať prílohu</Trans>
                </Button>
            }
            values={{
                isEdit: false,
                quotationId,
                requestId,
                orderId,
                productId,
                customerId,
                invoiceId,
                transferId,
                complaintId,
                userId,
                eventId,
                minAccessLevel,
            }}
            onFinishSuccessful={reloadAttachments}
            title={<Trans>Pridanie prílohy</Trans>}
            Form={AttachmentForm}
            Response={null}
            Failed={generalFailedPC(t`Prílohu sa nepodarilo pridať.`)}
        />;
    }

}

export default AddAttachment;